<template>
  <div class="page-content">
    <div class="breadcrumb-btns">
      <breadcrumb v-if="$isMobileScreen" :background="true"></breadcrumb>
      <div class="edit-btn-container mb-3">
        <button @click="$router.push('/change-password')" class="btn btn-simple btn-icon btn-setting text-center setting-btn">
        <span class="material-icons ml-2" data-wg-notranslate="">lock</span>
        <span class="d-none d-sm-block ml-2 mr-2 btn-text">Passwort ändern</span>
      </button>
      <button @click="$router.push('/edit-profile')" class="btn btn-simple btn-icon btn-setting text-center setting-btn">
      <span class="material-icons ml-2" data-wg-notranslate="">person</span>
      <span class="d-none d-sm-block ml-2 mr-2 btn-text">Meine Daten</span>
    </button>
  </div>
</div>
<div class="container-fluid">
  <div v-for="(listType,index) in collections" :key="index">
    <div v-if="hasCollections(listType) && index!='apps'">
      <div class="scroll-container">
        <merkliste-card v-for="(i, index) in getCollections(index,listType)" :key="index" :item="i" class="merkliste-card"></merkliste-card>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-center">
      <div class="no-collections" v-if="!hasAnyCollections">Speichere deine Favoriten! Erstelle dir jetzt deine Merkliste. </div>
      <button class="btn btn-active btn-home__reise create-collection-btn" @click="showModal">Merkliste erstellen</button>
    </div>
  </div>
</div>
<div class="footer-margin"></div>
</div>
</template>

<script>
  import { bus } from '@/main';
  import { getCollectionsOfUser } from '@/api/collection';
  import { showLoader, hideLoader } from '@/utils/helpers';
  import { screenSizeMixin } from '@/mixins/screenSizeMixin';

  export default {

    name: 'Profile',
    mixins: [screenSizeMixin],
    components: {
      CreateCollectionModal: () => import('@/components/modals/createCollection.vue'),
      MerklisteCard: () => import('@/components/cards/Merkliste.vue'),
      CardSlider: () => import('@/components/controls/CardSlider'),
      Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
    },
    computed:{
      user(){
        return this.$store.state.user;
      },
      userName(){
        return this.$store.state.user.name;
      },
      collections(){
        return this.$store.getters.getUserCollections;
      },
      hasAnyCollections(){
        if(this.collections!=null){
          for (var prop in this.collections) {
            if (Object.prototype.hasOwnProperty.call(this.collections, prop)) {
              if(this.hasCollections(this.collections[prop])){
                return true;
              }
            }
          }

        }
        return false;
      }
    },
    mounted(){
      this.loadCollections();
    },
    methods: {
      showLoader,
      hideLoader,
      getCollectionsOfUser,
      showModal () {
        bus.$emit('show-create-plan-modal', true)
      },
      closeCollectionDialogAndReloadCollection(){
        this.showAddNewCollection=false;
        this.loadCollections();
      },
      hasCollections(listType){
        if(listType.length>0){
          return true;
        }
        return false;
      },
      getCollectionTypeTitle(listType){
        var title = "";
        switch(listType){
          case "shared":
          title = "Mit mir geteilte Merklisten";
          break;
          case "own":
          title = "Eigene Merklisten";
          break;
          case "institution":
          title = "Merklisten meiner Institution";
          break;
          case "apps":
          title = "Merklisten dieser App";
          break;
        }
        return title
      },
      loadCollections(){
        this.loader = this.showLoader(this.loader);
        this.$store.dispatch('loadUserCollections').then(() => {
        }).catch(error => {
          this.$notify({
            duration: 4500,
            type: 'error',
            title: "Fehler bei der Anmeldung",
            text: error
          });
        }).finally(()=>{
          this.loader = this.hideLoader(this.loader);
        });
      },
      getCollections(listType,listArray){
        if(listType!="apps"){
          return listArray;
        }
      },
    }
  }
</script>
<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .page-content{
    min-height: 100vh;
    margin-bottom: 10vh;
    background-color: #FFF;
    @include responsive($breakpoint) {
      margin-bottom: $footer-sm-size;
    }

    @media (max-width: 639px) {
      margin-bottom: 50px;
    }
  }

  .no-collections{
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .create-collection-btn{
    margin-bottom: 30px;
  }

  .setting-btn {
    width: auto;
    margin-top: 20px;
    margin-right: 30px;

    @media (max-width: 991px) {
      margin-left: 0;
    }

    @media (max-width: 575px) {
      margin-top: 10px;
      margin-right: 10px;
      width: 50px;
    }

    span {
      @media (max-width: 575px) {
        margin-right: 0 !important;
      }
    }
  }

  .merkliste-card {
    width: 100%;
    min-height: 246px;
  }

  .container {
    padding-top: 30px;
  }

  .merklisten-slider {
    overflow: visible;
  }

  .scroll-container {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 30px;

    &::-webkit-scrollbar {
      height: 0;
      
    }

    @media (max-width: 992px) {
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }

  .edit-btn-container {
    width: 100%;
    text-align: right;

    button {
      background: #FFF;
      color: #157FAC;
      border: 2px solid #157FAC;
      width: 220px;
      min-width: 170px;
    }
  }

  .setting-btn:hover, .create-collection-btn:hover {
    background-color: $primary;
    color: #FFF;
  }

  .setting-btn {
    @media(max-width: 991px){
      width: 50px !important;
      min-width: 50px !important;
      height: 50px !important;
      border-radius: 50%;

      span {
        margin: 0 !important;
      }

      .btn-text {
        display: none !important;
      }
    }
  }

  .breadcrumb {
    background-color: #FFF !important;
  }

  .breadcrumb-btns {
    display: flex;
  }

  body[dir="rtl"] {
    .edit-btn-container{
      text-align: left;

      .setting-btn {
        margin-left: 30px;
      }
    }
  }
</style>